import './App.css';

function App() {
  return (
    <div className="App">
      {/* Background is handled in CSS */}
    </div>
  );
}

export default App;