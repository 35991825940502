// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/Cabal.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
   background-size: contain; /* Ensures the entire image fits inside the container */
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000;
  height: 100vh; /* Full height of the viewport */
  width: 100vw; /* Full width of the viewport */
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevents any potential scrollbars from appearing */
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yDAA0C;GACzC,wBAAwB,EAAE,uDAAuD;EAClF,2BAA2B;EAC3B,4BAA4B;EAC5B,sBAAsB;EACtB,aAAa,EAAE,gCAAgC;EAC/C,YAAY,EAAE,+BAA+B;EAC7C,SAAS;EACT,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB,EAAE,qDAAqD;AACzE","sourcesContent":[".App {\n  background-image: url('/public/Cabal.png');\n   background-size: contain; /* Ensures the entire image fits inside the container */\n  background-position: center;\n  background-repeat: no-repeat;\n  background-color: #000;\n  height: 100vh; /* Full height of the viewport */\n  width: 100vw; /* Full width of the viewport */\n  margin: 0;\n  padding: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden; /* Prevents any potential scrollbars from appearing */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
